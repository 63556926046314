import React from 'react';

export default ({ tags, handleTagClick = () => {} }) => {
  return (
    <div className="filter-form__box">
      <h3>Filter opties</h3>
      <div className="filter-form__items">
        {tags.map((tag, index) => (
          <label key={index}>
            <input
              type="checkbox"
              name="tags[]"
              value={tag}
              onClick={handleTagClick}
            />
            {tag}
          </label>
        ))}
      </div>
    </div>
  );
};
