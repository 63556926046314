export default () => {
  const querySelector = document.querySelector.bind(document);

  const mobileNav = (event) => {
    event.preventDefault();
    querySelector('.mobile-nav').classList.toggle('mobile-nav--visible');
    console.log('reachable...')
  };

  querySelector('.main-header__nav-toggle').addEventListener('click', mobileNav);
  querySelector('.mobile-nav__close').addEventListener('click', mobileNav);
};
