import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import ReactRailsUJS from 'react_ujs';

Rails.start();
Turbolinks.start();

window.Turbolinks = Turbolinks;
ReactRailsUJS.detectEvents();
delete window.Turbolinks;

import nav from '../lib/nav';
import paralax from '../lib/paralax';

import '../images/images';
import '../stylesheets/application.scss';

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener('turbolinks:load', e => {
  paralax();
  nav();
});
