import React, { Component } from 'react';
import { LightgalleryProvider } from 'react-lightgallery';
import axios from 'axios';

import Items from './Items';
import LoadMore from './LoadMore';
import FilterForm from './FilterForm';
import NoItems from './NoItems';

import 'lightgallery.js/dist/css/lightgallery.css';

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    const { tags, brands } = this.props;

    this.state = {
      url: `/api/v1/fireplaces`,
      loading: true,
      initialLoad: false,
      showButton: true,
      limit: 12,
      products: [],
      filtered: [],
      items: [],
      tags: tags,
      brands: brands,
      tagFilters: [],
      brandFilters: [],
      noItems: false,
      search: ''
    };
  }

  componentDidMount() {
    const { url } = this.state;
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        if (req.status === 200) {
          let res = JSON.parse(req.responseText);
          this.loadInitialData(res.products);
        }
      }
    };

    req.open('GET', url);
    req.send();
  }

  render() {
    const {
      items,
      loading,
      showButton,
      brands,
      tags,
      noItems,
      initialLoad
    } = this.state;
    return (
      <LightgalleryProvider galleryClassName="gallery__item">
        <div>
          <FilterForm
            handleSearch={this._updateSearch.bind(this)}
            loading={initialLoad}
            brands={brands}
            handleBrandClick={this._updateBrandFilters.bind(this)}
            tags={tags}
            handleTagClick={this._updateTagFilters.bind(this)}
          />
          <Items items={items} />
          <LoadMore
            loading={loading}
            showButton={showButton}
            handleClick={this.loadMoreItems}
          />
          <NoItems noItems={noItems} />
        </div>
      </LightgalleryProvider>
    );
  }

  loadInitialData = data => {
    const { products, filtered } = this.state;
    this.setState(
      {
        products: products.concat(data),
        filtered: filtered.concat(data),
        initialLoad: true
      },
      this.loadMoreItems
    );
  };

  loadMoreItems = () => {
    let { items, filtered, limit, showButton, noItems } = this.state;

    if (items.length !== filtered.length) {
      this.setState({ loading: true });

      if (filtered.length <= limit) {
        items = items.concat(filtered);
      } else {
        let index = items.length;
        let newIndex = index + limit;

        if (newIndex > filtered.length) {
          newIndex = filtered.length - 1;
        }

        let newArray = [];

        if (index === newIndex) {
          newArray = filtered.slice(index);
        } else {
          newArray = filtered.slice(index, newIndex);
        }

        items = items.concat(newArray);
      }

      if (items.length === filtered.length) {
        showButton = false;
      } else {
        showButton = true;
      }

      this.setState(
        {
          showButton: showButton,
          loading: false,
          items: items,
          noItems: false
        },
        this._triggerEvent
      );
    } else if (items.length === 0 && filtered.length === 0) {
      this.setState(
        {
          showButton: false,
          loading: false,
          noItems: true
        },
        this._triggerEvent
      );
    }
  };

  _filterItems = () => {
    const { products, brandFilters, tagFilters, search } = this.state;
    let data = [];
    let filtered = [];

    if (search !== '') {
      console.log('search: ' + search);

      for (let product of products) {
        if (product.name.toLowerCase().includes(search.toLowerCase())) {
          data = data.concat(product);
        }
      }
    } else {
      data = products;
    }

    if (brandFilters.length > 0 || tagFilters.length > 0) {
      for (let product of data) {
        let counter = 0;

        if (brandFilters.includes(product.brand)) {
          counter = counter + 1;
        }

        for (let tag of tagFilters) {
          if (product.tags.includes(tag)) {
            counter = counter + 1;
          }
        }

        if (counter > 0) {
          filtered.push(product);
        }
      }
    } else {
      filtered = data;
    }

    this.setState(
      {
        loading: true,
        filtered: filtered,
        items: []
      },
      this.loadMoreItems
    );
  };

  _updateBrandFilters = e => {
    const { brandFilters } = this.state;
    let value = e.target.value;
    let isChecked = e.target.checked;
    let filters = [];

    if (isChecked) {
      filters = brandFilters.concat(value);
    } else {
      for (let brand of brandFilters) {
        if (brand !== value) {
          filters = filters.concat(brand);
        }
      }
    }

    this.setState(
      {
        brandFilters: filters
      },
      this._filterItems
    );
  };

  _updateTagFilters = e => {
    const { tagFilters } = this.state;
    let value = e.target.value;
    let isChecked = e.target.checked;
    let filters = [];

    if (isChecked) {
      filters = tagFilters.concat(value);
    } else {
      for (let tag of tagFilters) {
        if (tag !== value) {
          filters = filters.concat(tag);
        }
      }
    }

    this.setState(
      {
        tagFilters: filters
      },
      this._filterItems
    );
  };

  _updateSearch = e => {
    this.setState(
      {
        search: e.target.value
      },
      this._filterItems
    );
  };

  _triggerEvent = () => {
    const event = new CustomEvent('reload-gallery');
    document.dispatchEvent(event);
  };
}
