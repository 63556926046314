import React from 'react';
import loaderImage from '../images/loading.gif';

export default ({ loading, showButton, handleClick = () => {} }) => {
  if (loading) {
    return (
      <div className="loader">
        <img className="loader__image" src={loaderImage} />
      </div>
    );
  } else if (showButton) {
    return (
      <div className="loader">
        <button className="loader__button" onClick={handleClick}>
          Toon meer
        </button>
      </div>
    );
  } else {
    return <div className="loader" />;
  }
};
