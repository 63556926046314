import React, { Component } from 'react';
import { LightgalleryProvider } from 'react-lightgallery';

import Items from './Items';
import LoadMore from './LoadMore';

import 'lightgallery.js/dist/css/lightgallery.css';

export default class BrandGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: `/api/v1/brands/${this.props.urlParam}`,
      loading: true,
      showButton: true,
      limit: 12,
      items: [],
      products: []
    };
  }

  componentDidMount() {
    const { url } = this.state;
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        if (req.status === 200) {
          let res = JSON.parse(req.responseText);
          this.loadInitialData(res.products);
        }
      }
    };

    req.open('GET', url);
    req.send();
  }

  render() {
    const { items, loading, showButton } = this.state;
    return (
      <LightgalleryProvider galleryClassName="gallery__item">
        <div>
          <Items items={items} />
          <LoadMore
            loading={loading}
            showButton={showButton}
            handleClick={this.loadMoreItems}
          />
        </div>
      </LightgalleryProvider>
    );
  }

  loadInitialData = data => {
    const { products } = this.state;
    this.setState(
      {
        products: products.concat(data)
      },
      this.loadMoreItems
    );
  };

  loadMoreItems = () => {
    let { items, products, limit, showButton } = this.state;

    if (items.length !== products.length) {
      this.setState({ loading: true });

      let index = items.length;
      let newIndex = index + limit;

      if (newIndex > products.length) {
        newIndex = products.length - 1;
      }

      let newArray = [];

      if (index === newIndex) {
        newArray = products.slice(index);
      } else {
        newArray = products.slice(index, newIndex);
      }

      items = items.concat(newArray);

      if (items.length === products.length) {
        showButton = false;
      } else {
        showButton = true;
      }

      this.setState(
        {
          showButton: showButton,
          loading: false,
          items: items
        },
        this._triggerEvent
      );
    }
  };

  _triggerEvent = () => {
    const event = new CustomEvent('reload-gallery');
    document.dispatchEvent(event);
  };
}
