import React from 'react';
import SearchInput from './SearchInput';
import BrandSelect from './BrandSelect';
import TagSelect from './TagSelect';

export default ({
  handleSearch = () => {},
  brands,
  handleBrandClick = () => {},
  tags,
  handleTagClick = () => {},
  loading
}) => {
  if (loading) {
    return (
      <form className="filter-form">
        <SearchInput handleSearch={handleSearch} />
        <div className="filter-form__container">
          <BrandSelect brands={brands} handleBrandClick={handleBrandClick} />
          <TagSelect tags={tags} handleTagClick={handleTagClick} />
        </div>
      </form>
    );
  } else {
    return <div className="filter-form">Een moment geduld...</div>;
  }
};
