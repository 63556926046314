import React from 'react';
import { LightgalleryItem } from 'react-lightgallery';

export default ({ image, name }) => {
  // return (
  //   <a href={image} className="gallery__item">
  //     <figure>
  //       <img src={image} />
  //       <figcaption>
  //         <h3>{name}</h3>
  //         <p>{description}</p>
  //       </figcaption>
  //     </figure>
  //   </a>
  // );
  return (
    <div className="gallery__item">
      <LightgalleryItem group="any" src={image}>
        <a className="">
          <figure>
            <img src={image} />
            <figcaption>
              <h3>{name}</h3>
            </figcaption>
          </figure>
        </a>
      </LightgalleryItem>
    </div>
  );
};
