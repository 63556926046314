import React, {Component} from 'react';
import {LightgalleryProvider, LightgalleryItem} from 'react-lightgallery';
import axios from 'axios';

export default class Galleries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.fetchData();
  }

  async fetchData() {
    // axios.get(`/api/v1/galleries/${this.props.key_name}`)
    //   .then(response => this.setState({items: this.state.items.concat(response.data.galleries)}))
    //   .catch(error => {})
    //   .then(() => {})
    // ;
    try {
      const response = await axios.get(`/api/v1/galleries/${this.props.key_name}`);
      this.setState({items: this.state.items.concat(response.data.galleries)});
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    const {items} = this.state;

    return (
      <LightgalleryProvider galleryClassName="materials-gallery">
        <div className="materials-gallery">
          {items.map(item =>
            <a className="">
              <LightgalleryItem group="any" src={item.image} key={item.id}>
                <figure>
                  <img src={item.image}/>
                  <figcaption>
                    <h3>{item.name}</h3>
                  </figcaption>
                </figure>
              </LightgalleryItem>
            </a>
          )}
        </div>
      </LightgalleryProvider>
    );
  }
}
