import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

import loaderImage from '../images/loading.gif';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageCount: this.props.images.length,
      imagesLoaded: 0
    };
  }

  renderImage(image) {
    return (
      <div key={image}>
        <img src={image} onLoad={this.imageLoaded} />
      </div>
    );
  }

  render() {
    const { images } = this.props;
    const slides = images.map(image => this.renderImage(image));

    if (this.state.imageCount < this.state.imagesLoaded) {
      return (
        <figure className="image loading">
          <img src={loaderImage} alt="foto's worden geladen"/>
        </figure>
      );
    } else {
      return (
        <Carousel
          showThumbs={false}
          showArrows={true}
          autoPlay={true}
          transitionTime={350}
        >
          {slides}
        </Carousel>
      );
    }
  }

  imageLoaded = () => {
    this.setState({ imagesLoaded: this.state.imagesLoaded + 1 });
  };
}
