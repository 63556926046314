import React from 'react';

export default ({ noItems }) => {
  if (noItems) {
    return (
      <div className="no-items">
        <p>Er zijn geen haarden gevonden die voldoen aan uw zoek opdracht.</p>
      </div>
    );
  } else {
    return null;
  }
};
