import React from 'react';
import Item from './Item';

export default ({ items }) => {
  return (
    <section id="productGallery" className="product-gallery item-container">
      {items.map(item => (
        <Item key={item.id} image={item.image} name={item.name} />
      ))}
    </section>
  );
};
