import React from 'react';

export default ({ brands, handleBrandClick = () => {} }) => {
  return (
    <div className="filter-form__box">
      <h3>Merken</h3>
      <div className="filter-form__items">
        {brands.map((brand, index) => (
          <label key={index}>
            <input
              type="checkbox"
              name="brands[]"
              value={brand}
              onClick={handleBrandClick}
            />
            {brand}
          </label>
        ))}
      </div>
    </div>
  );
};
