import React from 'react';

export default ({ handleSearch = () => {} }) => {
  return (
    <div className="filter-form__search">
      <input
        onKeyUp={handleSearch}
        className="search__input"
        type="text"
        placeholder="Zoek uw haard"
      />
    </div>
  );
};
